
import ApiRoutes from "../configs/endpoints.config";
import HttpClient from "./index.api";
import { getTokenLocal } from "utils/localStorage.util";
const baseURL = process.env.REACT_APP_API_URL;

class Banner extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      // config.headers["authkey"] = process.env.REACT_APP_AUTH_KEY;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  AllBannerConfig = ApiRoutes.Banner.All;
  AddBannerConfig = ApiRoutes.Banner.AddBanner;
  editBannerConfig = ApiRoutes.Banner.EditBanner;
  deleteBannerConfig = ApiRoutes.Banner.DeleteBanner;
  getBannerByIdConfig = ApiRoutes.Banner.GetBannerById;
 
  getAllBanner = async () => {
    return this.instance({
      method: this.AllBannerConfig.Method,
      url: this.AllBannerConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  
  addBanner = async (data) => {
    return this.instance({
      method: this.AddBannerConfig.Method,
      url: this.AddBannerConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  editBanner = async (data) => {
    return this.instance({
      method: this.editBannerConfig.Method,
      url: this.editBannerConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  deletebanner = async (data) => {
    return this.instance({
      method: this.deleteBannerConfig.Method,
      url: this.deleteBannerConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  getBannerById = async (data) => {
    return this.instance({
      method: this.getBannerByIdConfig.Method,
      url: this.getBannerByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
}

export default Banner;
