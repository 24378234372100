// assets
import {
  IconArticle,
  IconMap2,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconHandFinger,
  IconPackages,
  IconPictureInPicture,
  IconIdBadge2,
  IconIdBadge,
  IconUserCircle,
  IconCreditCard,
} from "@tabler/icons";

// constant
const icons = {
  IconMap2,
  IconArticle,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconHandFinger,
  IconPackages,
  IconPictureInPicture,
  IconIdBadge2,
  IconIdBadge,
  IconUserCircle,
  IconCreditCard,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "banner",
      title: "Banner",
      icon: icons.IconUsers,
      type: "collapse",
      children: [
        {
          id: "banner",
          title: "Banner",
          type: "item",
          url: "/banner",
          breadcrumbs: true,
        },
        {
          id: "add-banner",
          title: "Add Banner",
          type: "item",
          url: "/add-banner",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "package",
      title: " Package",
      icon: icons.IconPackages,
      type: "collapse",
      children: [
        {
          id: "package",
          title: "Package",
          type: "item",
          url: "/package",
          breadcrumbs: true,
        },
        {
          id: "add-package",
          title: "Add Package",
          type: "item",
          url: "/add-package",
          breadcrumbs: true,
        },
      ],
    },
    {
      //
      id: "earnsPoints",
      title: " Earned Point",
      icon: icons.IconPackages,
      type: "item",
      url: "/earned-point",
    },
    {
      id: "itr-package",
      title: "ITR Package",
      icon: icons.IconPackages,
      type: "collapse",
      children: [
        {
          id: "package",
          title: "Package",
          type: "item",
          url: "/itr-package",
          breadcrumbs: true,
        },
        {
          id: "add-itr-package",
          title: "Add Package",
          type: "item",
          url: "/add-itr-package",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "service-image",
      title: " Service Image",
      icon: icons.IconPictureInPicture,
      type: "collapse",
      children: [
        {
          id: "service-image",
          title: "Service Image",
          type: "item",
          url: "/service-image",
          breadcrumbs: true,
        },
        {
          id: "add-service-image",
          title: "Add Service Image",
          type: "item",
          url: "/add-service-image",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "enquiry",
      title: "Enquiry",
      icon: icons.IconIdBadge2,
      type: "item",
      url: "/enquiry",
    },
    {
      id: "earned",
      title: "Earned Points",
      icon: icons.IconIdBadge2,
      type: "item",
      url: "/earned-point",
    },
    {
      id: "Form-16",
      title: " Form 16 Enquiry",
      icon: icons.IconIdBadge,
      type: "item",
      url: "/form16-enquiry",
    },
    {
      id: "user-detail",
      title: "User Detail",
      icon: icons.IconUserCircle,
      type: "item",
      url: "/user-detail",
    },
    {
      id: "order-detail",
      title: "Order Detail",
      icon: icons.IconCreditCard,
      type: "item",
      url: "/order-detail",
    },
  ],
};

export default utilities;
