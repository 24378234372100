import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard/Default")));

//Banner Route
const EarnedPoints = Loadable(
  lazy(() => import("views/utilities/earnedPoints/index"))
); //
const Banner = Loadable(lazy(() => import("views/utilities/Banner/banner")));
const AddBanner = Loadable(
  lazy(() => import("views/utilities/Banner/add-banner"))
);
const EditBanner = Loadable(
  lazy(() => import("views/utilities/Banner/edit-banner"))
);

const Package = Loadable(lazy(() => import("views/utilities/package")));
const AddPackage = Loadable(
  lazy(() => import("views/utilities/package/add-package"))
);
const EditPackage = Loadable(
  lazy(() => import("views/utilities/package/edit-package"))
);
const ViewPackage = Loadable(
  lazy(() => import("views/utilities/package/view-package"))
);

const ITRPackage = Loadable(lazy(() => import("views/utilities/itr-package")));
const AddITRPackage = Loadable(
  lazy(() => import("views/utilities/itr-package/add-package"))
);
const EditITRPackage = Loadable(
  lazy(() => import("views/utilities/itr-package/edit-package"))
);
const ViewITRPackage = Loadable(
  lazy(() => import("views/utilities/itr-package/view-package"))
);

//for serviceImages

const ServiceImage = Loadable(
  lazy(() => import("views/utilities/serviceImage/index"))
);
const AddServiceImage = Loadable(
  lazy(() => import("views/utilities/serviceImage/addServiceImage"))
);
const EditServiceImage = Loadable(
  lazy(() => import("views/utilities/serviceImage/editServiceImage"))
);

const Enquiry = Loadable(lazy(() => import("views/utilities/enquiry")));
const Form16 = Loadable(lazy(() => import("views/utilities/form16")));

const ContactDeatil = Loadable(
  lazy(() => import("views/utilities/getContactDetail/index"))
);

const OrderDEtail = Loadable(
  lazy(() => import("views/utilities/orderDetail/index"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/earned-point",
      element: <EarnedPoints />,
    },

    {
      children: [
        {
          path: "banner",
          element: <Banner />,
        },
        {
          path: "add-banner",
          element: <AddBanner />,
        },
        {
          path: "edit-banner/:id",
          element: <EditBanner />,
        },
      ],
    },

    {
      children: [
        {
          path: "package",
          element: <Package />,
        },
        {
          path: "add-package",
          element: <AddPackage />,
        },
        {
          path: "edit-package/:id",
          element: <EditPackage />,
        },
        {
          path: "view-package/:id",
          element: <ViewPackage />,
        },
      ],
    },
    {
      children: [
        {
          path: "itr-package",
          element: <ITRPackage />,
        },
        {
          path: "add-itr-package",
          element: <AddITRPackage />,
        },
        {
          path: "edit-itr-package/:id",
          element: <EditITRPackage />,
        },
        {
          path: "view-itr-package/:id",
          element: <ViewITRPackage />,
        },
      ],
    },
    {
      children: [
        {
          path: "service-image",
          element: <ServiceImage />,
        },
        {
          path: "add-service-image",
          element: <AddServiceImage />,
        },
        {
          path: "edit-service-image/:id",
          element: <EditServiceImage />,
        },
      ],
    },
    {
      children: [
        {
          path: "enquiry",
          element: <Enquiry />,
        },
      ],
    },
    {
      children: [
        {
          path: "form16-enquiry",
          element: <Form16 />,
        },
      ],
    },
    {
      children: [
        {
          path: "user-detail",
          element: <ContactDeatil />,
        },
      ],
    },
    {
      children: [
        {
          path: "order-detail",
          element: <OrderDEtail />,
        },
      ],
    },
  ],
};

export default MainRoutes;
