import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux-slice/user.slice";
import customizationReducer from "store/customizationReducer";
import memberListReducer from "../redux-slice/member-list.slice";
import categoryReducer from "../redux-slice/category.slice";
import packageReducer from "../redux-slice/package.slice";
import enquiryReducer from "../redux-slice/enquiry.slice";
import form16Reducer from "../redux-slice/form16.slice";
import ITRPackage from "../redux-slice/itr-package.slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    customization: customizationReducer,
    member: memberListReducer,
    package: packageReducer,
    enquiry: enquiryReducer,
    form16: form16Reducer,
    category: categoryReducer,
    ITR: ITRPackage,
    
  },
});
