export const HttpMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Patch: "PATCH",
  Delete: "DELETE",
};

const ApiRoutes = {
  Dashboard: {
    Data: {
      Endpoint: "/auth/dashboard",
      Method: HttpMethod.Post,
    },
  },
  Auth: {
    Login: {
      Endpoint: "/auth/login",
      Method: HttpMethod.Post,
    },
  },

  Banner: {
    All: {
      Endpoint: "/banner/getAllBanner",
      Method: HttpMethod.Post,
    },

    AddBanner: {
      Endpoint: "/banner/addBanner",
      Method: HttpMethod.Post,
    },
    EditBanner: {
      Endpoint: "/banner/editBanner",
      Method: HttpMethod.Post,
    },
    DeleteBanner: {
      Endpoint: "/banner/deleteBanner",
      Method: HttpMethod.Post,
    },
    GetBannerById: {
      Endpoint: "/banner/getBannerById",
      Method: HttpMethod.Post,
    },
  },
  Package: {
    All: {
      Endpoint: "/package/getAllPackage",
      Method: HttpMethod.Post,
    },

    AddPackage: {
      Endpoint: "/package/addPackage",
      Method: HttpMethod.Post,
    },
    EditPackage: {
      Endpoint: "/package/editPackage",
      Method: HttpMethod.Post,
    },
    DeletePackage: {
      Endpoint: "/package/deletePackage",
      Method: HttpMethod.Post,
    },
    GetPackageById: {
      Endpoint: "/package/getPackageById",
      Method: HttpMethod.Post,
    },
  },
  ITRPackage: {
    All: {
      Endpoint: "/itr/getAllPackage",
      Method: HttpMethod.Post,
    },

    AddPackage: {
      Endpoint: "/itr/addPackage",
      Method: HttpMethod.Post,
    },
    EditPackage: {
      Endpoint: "/itr/editPackage",
      Method: HttpMethod.Post,
    },
    DeletePackage: {
      Endpoint: "/itr/deletePackage",
      Method: HttpMethod.Post,
    },
    GetPackageById: {
      Endpoint: "/itr/getPackageById",
      Method: HttpMethod.Post,
    },
  },
  ServiceBanner: {
    All: {
      Endpoint: "/serviceBanner/getAllServiceBanner",
      Method: HttpMethod.Post,
    },

    AddServiceBanner: {
      Endpoint: "/serviceBanner/addServiceBanner",
      Method: HttpMethod.Post,
    },
    EditServiceBanner: {
      Endpoint: "/serviceBanner/editServiceBanner",
      Method: HttpMethod.Post,
    },
    DeleteServiceBanner: {
      Endpoint: "/serviceBanner/deleteServiceBanner",
      Method: HttpMethod.Post,
    },
    GetBannerServiceById: {
      Endpoint: "/serviceBanner/getServiceBannerById",
      Method: HttpMethod.Post,
    },
  },
  Enquiry: {
    All: {
      Endpoint: "/enquiry/getAllEnquiry",
      Method: HttpMethod.Post,
    },
    GetAllEarnedPoints: {
      Endpoint: "/auth/getAllEarnedPoints",
      Method: HttpMethod.Post,
    },
  },
  Form16: {
    All: {
      Endpoint: "/form16/getAllForm16",
      Method: HttpMethod.Post,
    },
  },
  ContactUs: {
    contact: {
      Endpoint: "/contact/getAllContact",
      Method: HttpMethod.Post,
    },
  },
  OrderDetail: {
    order: {
      Endpoint: "/order/getAllOrder",
      Method: HttpMethod.Post,
    },
  },
};

export default ApiRoutes;
